import React, { useState, useEffect } from "react";
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-gtag"

// Import CSS
import "./../../styles/free-tools.css"
import "./../../styles/products.css"

// Import components
import GetStartedBanner from "./../../components/get-started-footer"

const SSLChecker = ({ data }) => (
  <Layout>
    <SEO
      title="SSL Certificate Checker - Odown Free Tools"
      description="Is your SSL certificate installed correctly? Use this free SSL checker tool to verify and troubleshoot any problems, ensuring secure connections."
      pathname={`/free-tools/ssl-checker/`}
      image={{
        src: "/general-image-og--ssl_certificate_checker.jpg",
        width: 1600,
        height: 800,
      }}
    
    />
    {/* HEADER */}
    <HeadingSection />
    <SSLCertificateCheckerSection />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 * Heading Section
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features freetools-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
        Free SSL Certificate Checker : <br /> Test Your Website Security Now
        </h1>
        <div className="FreeTool-heading__description">
        Is your SSL certificate installed correctly? Use this free SSL checker tool to verify and troubleshoot any problems, ensuring secure connections.        
        </div>
      </div>
      <div className="FreeTool-heading__button">
        <OutboundLink
          className="btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href="https://app.odown.io/signup"
        >
          Trial Odown free for 14 days
        </OutboundLink>
      </div>
    </div>
  )
}

/** * SSL Certificate Checker Section */
const SSLCertificateCheckerSection = () => {
  const [domain, setDomain] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleInputChange = (e) => {
    setDomain(e.target.value);
  };

  const handleCheckCertificate = async () => {
    if (!domain) {
      setError("Please enter a domain.");
      return;
    }
    setError("");
    setResult(null);
    setIsButtonDisabled(true);
    setCountdown(5); 

    try {
      const response = await fetch(
        `https://h7nirjrxdpfjxmf7xpra3ueoki0ofzmj.lambda-url.us-east-1.on.aws/?url=${domain}`
      );
      const ssldata = await response.json();

      if (ssldata.error) {
        setError(ssldata.error);
      } else {
        setResult(ssldata);
        setIsButtonDisabled(true);
      }
    } catch (err) {
      setError("An error occurred while fetching SSL data.");
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && isButtonDisabled) {
      setIsButtonDisabled(false);
    }
  }, [countdown, isButtonDisabled]);


  return (
    <section id="sslCertificateChecker" className="u-features freetools-list">
      <div className="wrapperContainer">
        <div className="free-ping-test-ssl">
          <span className="test-label">Secure Domain Name</span>
          <input
            type="text"
            placeholder="www.exampledomain.com"
            className="test-url-input"
            value={domain}
            onChange={handleInputChange}
          />
          <button className="start-test-button" onClick={handleCheckCertificate} disabled={isButtonDisabled}>
          {isButtonDisabled ? `${countdown}s` : "Test Now"}
          </button>
        </div>

        {error && <div className="error-message">{error}</div>}

        {result && (
  <div className="ssl-result-card">
    <div className="ssl-result-item">
      <span className="result-label">Hostname:</span>
      <span className="result-value">{result.hostname}</span>
    </div>
    <div className="ssl-result-item">
      <span className="result-label">Certificate Valid:</span>
      <span className="result-value">{result.certificate_valid ? "Yes" : "No"}</span>
    </div>
    <div className="ssl-result-item">
      <span className="result-label">Expiry Date:</span>
      <span className="result-value">{result.expiry_date}</span>
    </div>
    <div className="ssl-result-item">
      <span className="result-label">Validity Period:</span>
      <span className="result-value">{result.days_remaining} days</span>
    </div>
  </div>
)}
      </div>
    </section>
  );
};


export default SSLChecker
